import ornamante from '../images/portfolio/ornamante.jpg'
import spotifav from '../images/portfolio/spotifav.jpg'

const projects = [
    {
        name: 'Spotifav',
        desc: 'Full stack application that uses the Spotify API to display featured categories. You must first log in with your Spotify user to receive the token for the requests. Done with React, Context, JSX and Hooks.',
        type: 'img',
        img: spotifav,
        url: 'https://ohilari-spotify-app.netlify.app/'
    },
    {
        name: 'Ecommerce',
        desc: 'Online store done for Ornamante using Wordpress, WooCommerce, JS, PHP, HTML5 & CSS3. This ecommerce contains a cache system implemented for improve performance optimization and user experience.',
        type: 'img',
        img: ornamante,
        url: 'https://ornamante.com'
    }
]

export default projects;
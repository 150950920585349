import { Link } from 'gatsby'
import React, { Component } from 'react'
import projects from '../../helpers/lastProjects'

export default class RecentProject extends Component {
    render() {
        return (
            <div className="row-home">
                <h2><span role="img" aria-label="Last projects">🗂</span> Recent projects</h2>
                <div style={{ textAlign: 'center' }}>
                    {/* <iframe title="Working" src="https://giphy.com/embed/TdRWuQlaiDARWYCatW" width="240" height="120" frameBorder="0"></iframe> */}
                    <div className="projects">
                        {projects.map((project, index) => (
                            <div key={index} className="project-card">
                                {project.type === 'img'
                                    ?
                                    <img alt={project.name} src={project.img} loading="lazy" />
                                    :
                                    <video autoPlay loop>
                                        <source src={project.img} type="video/mp4" />
                                    </video>
                                }
                            </div>
                        ))}
                    </div>
                    <h3 className="skill-title">Want to see more projects? Visit the <span role="img" aria-label="Still working on it">👉 </span> <Link to="/portfolio" className="link-inline"> portfolio</Link> page!</h3>
                </div>
            </div>
        )
    }
}
